<template>
  <v-list-item
    dense
    :class="classes"
  >
    <v-list-item-content>
      <v-list-item-title>{{ assunto }}</v-list-item-title>
      <v-list-item-subtitle>{{ `${durationFormated} - ${orador}` }}</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-avatar @click="playing ? pause() : play()">
      <v-icon
        :size="25"
        v-if="playing"
        color="blue lighten-1"
      >
        mdi-pause-circle-outline
      </v-icon>
      <v-icon
        :size="25"
        v-else
        color="green darken-2"
      >
        mdi-play-circle-outline
      </v-icon>
    </v-list-item-avatar>
  </v-list-item>
</template>
<script>
import { formatSecs } from '@/utils'
import { mapState } from 'vuex'

import {
  ACTION_AUDIO_LISTEN,
  ACTION_AUDIO_PAUSE
} from '@/constants'

export default {
  props: [
    'id',
    'orador',
    'assunto',
    'duracao',
    'audioType',
    'classes'
  ],

  computed: {
    ...mapState({
      playing ({ searchAudios: { stream } }) {
        if (!stream) return false
        if (stream.action === 'PAUSE') return false
        if (stream.action === 'ENDED') return false
        return Number(stream.id) === Number(this.id)
      }
    }),

    durationFormated () {
      return formatSecs(this.duracao)
    }
  },

  methods: {
    play () {
      this.$store.dispatch(ACTION_AUDIO_LISTEN, {
        id: this.id,
        arquivoType: this.audioType
      })
    },

    pause () {
      this.$store.dispatch(ACTION_AUDIO_PAUSE)
    }
  }
}
</script>

<template>
  <tr :class="odd ? 'odd' : 'even'">
    <td
      :id="`td-escutar-${id}`"
      class="tddmcvivo text-center max40px"
      style="cursor: pointer; padding-left: 5px; width: 40px; max-width: 40px; padding-right: 0;"
      @click="$emit('on-listen-audio')"
    >
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            :id="`btn-escutar-${id}`"
            icon
            v-on="on"
          >
            <v-icon
              :id="`icon-escutar-${id}`"
              color="blue darken-2"
            >
              mdi-play-circle-outline
            </v-icon>
          </v-btn>
        </template>
        <span>Clique para Escutar</span>
      </v-tooltip>
    </td>

    <td
      :id="`td-assunto-${id}`"
      class="max23vw"
    >
      <a
        :id="`dialog-detalhes-audio-${id}`"
        :href="'#'.concat(`${assunto}`.toLowerCase().replace(/[^a-z0-9]/g, '-').replace(/--/g, '-'))"
        @click="$emit('on-view', id)"
        style="text-decoration: none"
      >
        <span>{{ assunto }}</span>
        <br>
        <small :id="`small-duracao-${id}`">
          <i :id="`i-duracao-${id}`">{{ durationFormated }}</i>
        </small>&nbsp;-&nbsp;
        <small v-if="!orador || orador == dirigente"><b>{{ dirigente }}</b></small>
        <small v-if="orador && orador != dirigente"><b>{{ orador }}</b></small>
      </a>

      <div v-if="viewPhone">
        <div style="margin: 5px 0">
          <v-divider />
        </div>

        <a
          :id="`dialog-detalhes-sessao-${id}`"
          :href="'#'.concat(`${sessao} ${album || formatDate}`.toLowerCase().replace(/[^a-z0-9]/g, '-').replace(/--/g, '-'))"
          @click="onOpenList"
          style="text-decoration: none"
        >
          <small>
            {{ core }}
            <br>
            {{ formatDegreeDate }}
          </small>
        </a>

        <div style="margin: 5px 0">
          <v-divider />
        </div>

        <div style="margin: 0 0 9px">
          <AudioSearchRevisorBtn
            v-if="showRevisor"

            :id="id"
            :color="color"

            :user-name="revisao ? revisao.user.name : ''"
            :user-degree="revisao ? revisao.user.degree : ''"
            :user-review="Boolean(revisao ? revisao.user.id : false)"

            :review-user="revisorNome"
            :review-status="aprovado"
            :review-degree="revisorGrau"
          />
        </div>
      </div>
    </td>

    <td
      :id="`td-nucleo-${id}`"
      v-if="!viewPhone"
    >
      {{ core }}
      <br v-if="core && formatDate">
      <i v-if="formatDate">{{ formatDate }}</i>
    </td>

    <td
      :id="`td-sessao-${id}`"
      v-if="!viewPhone"
      class="max23vw"
    >
      <v-tooltip bottom>
        <template #activator="{ on }">
          <a
            :id="`dialog-detalhes-audio-${id}`"
            :href="'#'.concat(`${sessao || ''} ${album || formatDate}`.toLowerCase().replace(/[^a-z0-9]/g, '-').replace(/--/g, '-'))"
            v-on="on"
            @click="onOpenList"
            style="text-decoration: none"
          >
            <span>{{ album ? album : sessao }}</span>
            <br v-if="(album ? album : sessao) && (dirigente && orador !== dirigente)">
            <small v-if="dirigente && orador !== dirigente">Dirigente: </small>
            <b v-if="dirigente && orador !== dirigente">{{ dirigente || 'não informado' }}</b>
          </a>
        </template>
        <span>Clique aqui para ouvir a sessão inteira</span>
      </v-tooltip>
    </td>

    <td
      :id="`td-revisao-${id}`"
      v-if="isAdmin || (!viewPhone && showRevisor)"
      class="tddmcvivo text-center"
    >
      <v-tooltip
        bottom
        color="primary"
        v-if="isAdmin"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            x-small
            icon
            color="primary"
            :href="audioUrl"
            target="_blank"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-monitor-arrow-down-variant
            </v-icon>
          </v-btn>
        </template>
        <span>Fazer download do Áudio</span>
      </v-tooltip>

      <v-tooltip
        bottom
        color="primary"
        v-if="isAdmin"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            x-small
            icon
            color="primary"
            @click="handleCopy"
            v-bind="attrs"
            v-on="on"
            class="ml-1"
          >
            <v-icon>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </template>
        <span>Copiar link do Áudio</span>
      </v-tooltip>

      <AudioSearchRevisorBtn
        :id="id"
        :color="color"

        v-if="viewPhone && showRevisor"

        :user-name="revisao ? revisao.user.name : ''"
        :user-degree="revisao ? revisao.user.degree : ''"
        :user-review="Boolean(revisao ? revisao.id : false)"

        :review-user="revisorNome"
        :review-status="aprovado"
        :review-degree="revisorGrau"
      />
    </td>
  </tr>
</template>

<script>
import { formatSecs, getToken, getSession } from '@/utils'
import { getHostAPI } from '@/plugins/Amplify'
import AudioSearchRevisorBtn from './AudioSearchRevisorBtn'
import copy from 'copy-to-clipboard'

export default {
  components: {
    AudioSearchRevisorBtn
  },

  props: {
    id: {},
    data: {},
    color: {},
    album: {},
    nucleo: {},
    orador: {},
    revisao: {},
    sessaoId: {},
    sessao: {},
    duracao: {},
    assunto: {},
    aprovado: {},
    dirigente: {},
    revisorGrau: {},
    revisorNome: {},
    showRevisor: {},
    odd: {}
  },

  data () {
    return {
      isAdmin: false,
      audioUrl: null
    }
  },

  mounted () {
    getToken().then((token) => (
      getSession().then((session) => {
        const isAdmin = Boolean(session?.administrador)
        const audioUrl = isAdmin ? `${getHostAPI()}/audios/download?id=${this.id}&token=${token}` : null
        Object.assign(this, { isAdmin, audioUrl })
      })
    ))
  },

  methods: {
    onOpenList (e) {
      e.preventDefault()
      this.$emit('on-open-list')
    },

    handleCopy () {
      getSession().then((session) => {
        const isAdmin = Boolean(session?.administrador)
        if (!isAdmin) return
        return getToken().then((token) => {
          const audioUrl = `${getHostAPI()}/audios/download?id=${this.id}&token=${token}`
          copy(audioUrl)
        })
      })
    }
  },

  computed: {
    viewPhone () {
      return this.$vuetify.breakpoint.xs
    },

    core () {
      const core = String(this.nucleo || '').trim().toLowerCase().split(' ').map((word) => {
        if (['da', 'de', 'do', 'a', 'e', 'i', 'o', 'u'].includes(word)) return word
        return word.charAt(0).toUpperCase() + word.slice(1)
      }).join(' ')

      if (core === 'Sede Geral') return core
      if (core.indexOf('Dav') === 0) return core.replace('Dav ', 'DAV ')
      return `Núcleo ${core}`
    },

    formatDate () {
      return this.data
        ? this.data.split('-').reverse().join('/')
        : 'Sem Data'
    },

    formatDegreeDate () {
      if (!this.data) return this.sessao
      const year = this.data.split('-')[0]
      const anual = [22, 23, 24, 25, 26, 27, 28, 30, 31].includes(this.sessaoId)
      if (anual) return `${this.sessao} | ${year}`
      return `${this.sessao} ${this.data.split('-').reverse().join('/')}`
    },

    durationFormated () {
      return formatSecs(this.duracao)
    }
  }
}
</script>

<style scoped>
  tr.odd>td {
    background-color: #0094d90f
  }
</style>
